/*
 * Beautify Dropzone
 *
 * @author Uday Hiwarale <uhiwarale@gmail.com>
 * https://www.github.com/thatisuday/ngDropzone
 *
 * Remove huge border radius, odd colors
 * Remove unnecessary preview elements like size, name etc.
 * Use .md, .sm, .xs classes with .dropzone class for smaller thumbnail previews
 * * * * * * * * * * * * * * *
 * Make sure you set font family property in body else add below style to your dropzones
 * font-family:sans-serif;
**/
.dropzone {
  position: relative;
  padding: 5px;
  cursor: pointer;
  border: 1px solid #eee;
  /* Drop message */
  /* Preview */ }
  .dropzone .dz-message {
    margin: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -webkit-transform: translate(-50%, -50%);
    font-weight: 300;
    color: #999;
    font-size: 12px;
    text-transform: uppercase; }
  .dropzone .dz-preview {
    margin: 5px;
    min-height: auto;
    /* Preview image */
    /* Preview info */
    /* Progress */
    /* Callback icons */
    /* Error */
    /* Remove file */ }
    .dropzone .dz-preview.dz-file-preview .dz-image {
      border-radius: 3px; }
    .dropzone .dz-preview .dz-image {
      border-radius: 3px; }
    .dropzone .dz-preview .dz-size,
    .dropzone .dz-preview .dz-filename {
      display: none; }
    .dropzone .dz-preview .dz-progress {
      left: 0;
      width: 90%;
      border-radius: 3px;
      margin-left: 5%;
      margin-right: 5%; }
      .dropzone .dz-preview .dz-progress .dz-upload {
        background: #8BC34A;
        background: -webkit-linear-gradient(top, #E6EE9C, #8BC34A);
        background: linear-gradient(to bottom, #E6EE9C, #8BC34A); }
    .dropzone .dz-preview .dz-success-mark, .dropzone .dz-preview .dz-error-mark {
      margin: 0;
      width: 100%;
      height: 100%; }
      .dropzone .dz-preview .dz-success-mark svg, .dropzone .dz-preview .dz-error-mark svg {
        position: absolute;
        margin-top: -6px;
        width: 50%;
        height: 50%;
        transform: translate(-50%, -50%);
        -moz-transform: translate(-50%, -50%);
        -webkit-transform: translate(-50%, -50%); }
    .dropzone .dz-preview.dz-error .dz-error-message {
      top: auto;
      left: 0;
      font-size: 12px;
      font-weight: 300;
      line-height: 14px;
      border-radius: 3px;
      background: #FF9800;
      background: -webkit-linear-gradient(top, #FFA726, #FF9800);
      background: linear-gradient(to bottom, #FFA726, #FF9800);
      text-align: center;
      margin-top: 10px;
      margin-left: -10px; }
    .dropzone .dz-preview.dz-error .dz-error-message:after {
      border-bottom: 6px solid #FF9800; }
    .dropzone .dz-preview .dz-remove {
      position: absolute;
      bottom: 0;
      width: 100%;
      color: #fff;
      font-size: 11px;
      padding: 3px 0;
      background-color: rgba(100, 100, 100, 0.5);
      font-weight: 300;
      z-index: 10;
      text-transform: uppercase;
      white-space: nowrap;
      overflow: hidden;
      text-decoration: none; }
  .dropzone.md .dz-preview .dz-image {
    width: 100px;
    height: 100px; }
  .dropzone.md .dz-preview.dz-error .dz-error-message {
    margin-left: -20px; }
  .dropzone.sm .dz-preview .dz-image {
    width: 80px;
    height: 80px; }
  .dropzone.sm .dz-preview.dz-error .dz-error-message {
    margin-left: -30px; }
  .dropzone.sm .dz-preview .dz-remove {
    font-size: 10px; }
  .dropzone.xs .dz-preview .dz-image {
    width: 60px;
    height: 60px; }
  .dropzone.xs .dz-preview.dz-error .dz-error-message {
    margin-left: -40px; }
  .dropzone.xs .dz-preview .dz-remove {
    font-size: 9px; }
